import axios from "axios";
import { AUTH_FAIL, AUTH_REQUEST, LOGIN, LOGOUT } from "./auth.types";
import { setAuthToken } from "./auth.utils";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const login = (username, password) => async (dispatch) => {
  dispatch({
    type: AUTH_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/shops/login`,
      { username, password },
      config
    );
    dispatch({
      type: LOGIN,
      payload: data.user,
    });
    setAuthToken(data.token);
    localStorage.setItem("authInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: AUTH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// const createUser = async (authtoken) => {
//   const user = await createOrUpdateUser(authtoken);

// }

export const loadUserFromLocalStorage = () => (dispatch) => {
  if (localStorage.authInfo) {
    const authInfo = JSON.parse(localStorage.authInfo);
    setAuthToken(authInfo.token);
    dispatch({
      type: LOGIN,
      payload: authInfo.user,
    });
  }
};

export const logout = () => {
  localStorage.removeItem("authInfo");
  return {
    type: LOGOUT,
  };
};
