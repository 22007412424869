import { toast } from "react-toastify";
import {
  FLAVOR_CREATE_FAIL,
  FLAVOR_CREATE_REQUEST,
  FLAVOR_CREATE_RESET,
  FLAVOR_CREATE_SUCCESS,
  FLAVOR_DELETE_FAIL,
  FLAVOR_DELETE_REQUEST,
  FLAVOR_DELETE_SUCCESS,
  FLAVOR_LIST_FAIL,
  FLAVOR_LIST_REQUEST,
  FLAVOR_LIST_SUCCESS,
  FLAVOR_UPDATE_FAIL,
  FLAVOR_UPDATE_REQUEST,
  FLAVOR_UPDATE_SUCCESS,
} from "./flavor.types";

export const flavorListReducer = (state = { flavors: [] }, action) => {
  switch (action.type) {
    case FLAVOR_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case FLAVOR_LIST_SUCCESS:
      return {
        loading: false,
        flavors: action.payload,
        success: true,
      };
    case FLAVOR_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const flavorCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FLAVOR_CREATE_REQUEST:
      return {
        loading: true,
      };
    case FLAVOR_CREATE_SUCCESS:
      toast.success("Sabor creado");
      return {
        loading: false,
        flavor: action.payload,
        success: true,
      };
    case FLAVOR_CREATE_FAIL:
      toast.error(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    case FLAVOR_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const flavorDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FLAVOR_DELETE_REQUEST:
      return {
        loading: true,
      };
    case FLAVOR_DELETE_SUCCESS:
      toast.success("Sabor borrado");
      return {
        loading: false,
        success: true,
      };
    case FLAVOR_DELETE_FAIL:
      toast.error("Error borrando sabor: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const flavorUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FLAVOR_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case FLAVOR_UPDATE_SUCCESS:
      toast.success("Sabor actualizado");
      return {
        loading: false,
        success: true,
      };
    case FLAVOR_UPDATE_FAIL:
      toast.error("Error actualizando sabor: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
