import { AUTH_FAIL, AUTH_REQUEST, LOGIN, LOGOUT } from "./auth.types";

const initialState = {
  authenticated: false,
  currentUser: null,
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
        currentUser: payload,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    case AUTH_REQUEST:
      return {
        loading: true,
        error: null,
      };
    case AUTH_FAIL:
      return {
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
