import { toast } from "react-toastify";
import {
  TOPPING_CREATE_FAIL,
  TOPPING_CREATE_REQUEST,
  TOPPING_CREATE_RESET,
  TOPPING_CREATE_SUCCESS,
  TOPPING_DELETE_FAIL,
  TOPPING_DELETE_REQUEST,
  TOPPING_DELETE_SUCCESS,
  TOPPING_LIST_FAIL,
  TOPPING_LIST_REQUEST,
  TOPPING_LIST_SUCCESS,
} from "./topping.types";

export const toppingListReducer = (state = { toppings: [] }, action) => {
  switch (action.type) {
    case TOPPING_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case TOPPING_LIST_SUCCESS:
      return {
        loading: false,
        toppings: action.payload,
        success: true,
      };
    case TOPPING_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const toppingCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPPING_CREATE_REQUEST:
      return {
        loading: true,
      };
    case TOPPING_CREATE_SUCCESS:
      toast.success("Topping creado");
      return {
        loading: false,
        topping: action.payload,
        success: true,
      };
    case TOPPING_CREATE_FAIL:
      toast.error(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    case TOPPING_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const toppingDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPPING_DELETE_REQUEST:
      return {
        loading: true,
      };
    case TOPPING_DELETE_SUCCESS:
      toast.success("Topping borrado");
      return {
        loading: false,
        success: true,
      };
    case TOPPING_DELETE_FAIL:
      toast.error("Error borrando topping: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
