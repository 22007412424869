export const TOPPING_LIST_REQUEST = "TOPPING_LIST_REQUEST";
export const TOPPING_LIST_SUCCESS = "TOPPING_LIST_SUCCESS";
export const TOPPING_LIST_FAIL = "TOPPING_LIST_FAIL";

export const TOPPING_CREATE_REQUEST = "TOPPING_CREATE_REQUEST";
export const TOPPING_CREATE_SUCCESS = "TOPPING_CREATE_SUCCESS";
export const TOPPING_CREATE_FAIL = "TOPPING_CREATE_FAIL";
export const TOPPING_CREATE_RESET = "TOPPING_CREATE_RESET";

export const TOPPING_DELETE_REQUEST = "TOPPING_DELETE_REQUEST";
export const TOPPING_DELETE_SUCCESS = "TOPPING_DELETE_SUCCESS";
export const TOPPING_DELETE_FAIL = "TOPPING_DELETE_FAIL";
