export const FLAVOR_LIST_REQUEST = "FLAVOR_LIST_REQUEST";
export const FLAVOR_LIST_SUCCESS = "FLAVOR_LIST_SUCCESS";
export const FLAVOR_LIST_FAIL = "FLAVOR_LIST_FAIL";

export const FLAVOR_CREATE_REQUEST = "FLAVOR_CREATE_REQUEST";
export const FLAVOR_CREATE_SUCCESS = "FLAVOR_CREATE_SUCCESS";
export const FLAVOR_CREATE_FAIL = "FLAVOR_CREATE_FAIL";
export const FLAVOR_CREATE_RESET = "FLAVOR_CREATE_RESET";

export const FLAVOR_DELETE_REQUEST = "FLAVOR_DELETE_REQUEST";
export const FLAVOR_DELETE_SUCCESS = "FLAVOR_DELETE_SUCCESS";
export const FLAVOR_DELETE_FAIL = "FLAVOR_DELETE_FAIL";

export const FLAVOR_UPDATE_REQUEST = "FLAVOR_UPDATE_REQUEST";
export const FLAVOR_UPDATE_SUCCESS = "FLAVOR_UPDATE_SUCCESS";
export const FLAVOR_UPDATE_FAIL = "FLAVOR_UPDATE_FAIL";
