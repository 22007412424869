import { sidebarReducer } from "./sidebar/sidebar.reducer";
import { combineReducers } from "redux";
import {
  productCreateReducer,
  productDeleteReducer,
  productListReducer,
  productUpdateReducer,
} from "./products/product.reducers";
import {
  subcategoryCreateReducer,
  subcategoryDeleteReducer,
  subcategoryListReducer,
  subcategoryUpdateReducer,
} from "./subcategories/subcategory.reducers";
import {
  categoryListReducer,
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryUpdateReducer,
} from "./categories/category.reducers";
import {
  flavorListReducer,
  flavorCreateReducer,
  flavorDeleteReducer,
  flavorUpdateReducer,
} from "./flavors/flavor.reducers";
import {
  toppingListReducer,
  toppingCreateReducer,
  toppingDeleteReducer,
} from "./toppings/topping.reducers";

import { ordersReducer } from "./orders/orders.reducer";
import { shopsReducer } from "./shops/shops.reducer";
import { authReducer } from "./auth/auth.reducer";
import {
  shopConfigReducer,
  shopConfigUpdateReducer,
} from "./shop-config/shop-config.reducers";
import {
  couponCreateReducer,
  couponListReducer,
  couponUpdateReducer,
} from "./coupons/coupon.reducers";

const reducer = combineReducers({
  sidebar: sidebarReducer,
  productList: productListReducer,
  productCreate: productCreateReducer,
  productDelete: productDeleteReducer,
  productUpdate: productUpdateReducer,
  categoryList: categoryListReducer,
  categoryCreate: categoryCreateReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  subcategoryList: subcategoryListReducer,
  subcategoryCreate: subcategoryCreateReducer,
  subcategoryDelete: subcategoryDeleteReducer,
  subcategoryUpdate: subcategoryUpdateReducer,
  flavorList: flavorListReducer,
  flavorCreate: flavorCreateReducer,
  flavorDelete: flavorDeleteReducer,
  flavorUpdate: flavorUpdateReducer,
  toppingList: toppingListReducer,
  toppingCreate: toppingCreateReducer,
  toppingDelete: toppingDeleteReducer,
  orders: ordersReducer,
  shops: shopsReducer,
  auth: authReducer,
  shopConfig: shopConfigReducer,
  shopConfigUpdate: shopConfigUpdateReducer,
  couponList: couponListReducer,
  couponCreate: couponCreateReducer,
  couponUpdate: couponUpdateReducer,
});

export default reducer;
