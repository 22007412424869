import { toast } from "react-toastify";
import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_RESET,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
} from "./category.types";

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      };
    case CATEGORY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return {
        loading: true,
      };
    case CATEGORY_CREATE_SUCCESS:
      toast.success("Categoría creada");
      return {
        loading: false,
        category: action.payload,
        success: true,
      };
    case CATEGORY_CREATE_FAIL:
      toast.error(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return {
        loading: true,
      };
    case CATEGORY_DELETE_SUCCESS:
      toast.success("Categoría borrada");
      return {
        loading: false,
        success: true,
      };
    case CATEGORY_DELETE_FAIL:
      toast.error("Error borrando categoría: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const categoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case CATEGORY_UPDATE_SUCCESS:
      toast.success("Categoría actualizada");
      return {
        loading: false,
        success: true,
      };
    case CATEGORY_UPDATE_FAIL:
      toast.error("Error actualizando categoría: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
