import {
  NEW_ORDER_NOTIFICATION,
  NEW_ORDER_NOTIFICATION_RESET,
  ORDERS_FAIL,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_UPDATE_STATUS,
} from "./orders.types";

export const ordersReducer = (
  state = { orders: [], unreadNotifications: 0 },
  action
) => {
  switch (action.type) {
    case ORDERS_REQUEST:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case ORDERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        orders: action.payload,
      };
    case ORDERS_UPDATE_STATUS:
      return {
        ...state,
        error: null,
        loading: false,
        orders: state.orders.map((o) =>
          o._id === action.payload._id ? action.payload : o
        ),
      };
    case ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_ORDER_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications + 1,
      };
    case NEW_ORDER_NOTIFICATION_RESET:
      return {
        ...state,
        unreadNotifications: 0,
      };
    default:
      return state;
  }
};
