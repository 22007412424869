import { toast } from "react-toastify";
import {
  COUPON_CREATE_FAIL,
  COUPON_CREATE_REQUEST,
  COUPON_CREATE_RESET,
  COUPON_CREATE_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  COUPON_UPDATE_FAIL,
  COUPON_UPDATE_REQUEST,
  COUPON_UPDATE_SUCCESS,
} from "./coupon.types";

export const couponListReducer = (state = { coupons: [] }, action) => {
  switch (action.type) {
    case COUPON_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case COUPON_LIST_SUCCESS:
      return {
        loading: false,
        coupons: action.payload,
      };
    case COUPON_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const couponCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_CREATE_REQUEST:
      return {
        loading: true,
      };
    case COUPON_CREATE_SUCCESS:
      toast.success("Cupón creado");
      return {
        loading: false,
        coupon: action.payload,
        success: true,
      };
    case COUPON_CREATE_FAIL:
      toast.error(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    case COUPON_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const couponUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case COUPON_UPDATE_SUCCESS:
      // toast.success("Cupón actualizado");
      return {
        loading: false,
        success: true,
      };
    case COUPON_UPDATE_FAIL:
      toast.error("Error actualizando cupón: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
