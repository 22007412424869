import { toast } from "react-toastify";
import {
  SHOP_CONFIG_FAIL,
  SHOP_CONFIG_REQUEST,
  SHOP_CONFIG_SUCCESS,
  SHOP_CONFIG_UPDATE_FAIL,
  SHOP_CONFIG_UPDATE_REQUEST,
  SHOP_CONFIG_UPDATE_SUCCESS,
} from "./shop-config.types";

const INITIAL_STATE = {
  shopConfig: null,
};

export const shopConfigReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOP_CONFIG_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case SHOP_CONFIG_SUCCESS:
      return {
        loading: false,
        shopConfig: action.payload,
      };
    case SHOP_CONFIG_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const shopConfigUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOP_CONFIG_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case SHOP_CONFIG_UPDATE_SUCCESS:
      toast.success("Parámetros actualizados");
      return {
        loading: false,
        success: true,
      };
    case SHOP_CONFIG_UPDATE_FAIL:
      toast.error(
        "Error actualizando los parámetros de la tienda: " + action.payload
      );
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
