import {
  SHOPS_REQUEST,
  SHOPS_SUCCESS,
  SHOPS_FAIL,
  SHOPS_CREATE,
  SHOPS_UPDATE,
} from "./shops.types";

export const shopsReducer = (state = { shops: [] }, action) => {
  switch (action.type) {
    case SHOPS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case SHOPS_SUCCESS:
      return {
        loading: false,
        shops: action.payload,
      };
    case SHOPS_CREATE:
      return {
        loading: false,
        shops: [...state.shops, action.payload],
      };
    case SHOPS_UPDATE:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      };
    case SHOPS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
