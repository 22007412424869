export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SUBCATEGORY_LIST_FAIL = "SUBCATEGORY_LIST_FAIL";

export const SUBCATEGORY_CREATE_REQUEST = "SUBCATEGORY_CREATE_REQUEST";
export const SUBCATEGORY_CREATE_SUCCESS = "SUBCATEGORY_CREATE_SUCCESS";
export const SUBCATEGORY_CREATE_FAIL = "SUBCATEGORY_CREATE_FAIL";
export const SUBCATEGORY_CREATE_RESET = "SUBCATEGORY_CREATE_RESET";

export const SUBCATEGORY_DELETE_REQUEST = "SUBCATEGORY_DELETE_REQUEST";
export const SUBCATEGORY_DELETE_SUCCESS = "SUBCATEGORY_DELETE_SUCCESS";
export const SUBCATEGORY_DELETE_FAIL = "SUBCATEGORY_DELETE_FAIL";

export const SUBCATEGORY_UPDATE_REQUEST = "SUBCATEGORY_UPDATE_REQUEST";
export const SUBCATEGORY_UPDATE_SUCCESS = "SUBCATEGORY_UPDATE_SUCCESS";
export const SUBCATEGORY_UPDATE_FAIL = "SUBCATEGORY_UPDATE_FAIL";
