import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./redux/store";
import { loadUserFromLocalStorage } from "./redux/auth/auth.actions";

//React.icons = icons;

// Check if logged in user from local storage
store.dispatch(loadUserFromLocalStorage());

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
