import { toast } from "react-toastify";
import {
  SUBCATEGORY_CREATE_FAIL,
  SUBCATEGORY_CREATE_REQUEST,
  SUBCATEGORY_CREATE_RESET,
  SUBCATEGORY_CREATE_SUCCESS,
  SUBCATEGORY_DELETE_FAIL,
  SUBCATEGORY_DELETE_REQUEST,
  SUBCATEGORY_DELETE_SUCCESS,
  SUBCATEGORY_LIST_FAIL,
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_UPDATE_FAIL,
  SUBCATEGORY_UPDATE_REQUEST,
  SUBCATEGORY_UPDATE_SUCCESS,
} from "./subcategory.types";

export const subcategoryListReducer = (
  state = { subcategories: [] },
  action
) => {
  switch (action.type) {
    case SUBCATEGORY_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case SUBCATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        subcategories: action.payload,
      };
    case SUBCATEGORY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const subcategoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBCATEGORY_CREATE_REQUEST:
      return {
        loading: true,
      };
    case SUBCATEGORY_CREATE_SUCCESS:
      toast.success("Subcategoría creada");
      return {
        loading: false,
        category: action.payload,
        success: true,
      };
    case SUBCATEGORY_CREATE_FAIL:
      toast.error(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    case SUBCATEGORY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const subcategoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBCATEGORY_DELETE_REQUEST:
      return {
        loading: true,
      };
    case SUBCATEGORY_DELETE_SUCCESS:
      toast.success("Subcategoría borrada");
      return {
        loading: false,
        success: true,
      };
    case SUBCATEGORY_DELETE_FAIL:
      toast.error("Error borrando subcategoría: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const subcategoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBCATEGORY_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case SUBCATEGORY_UPDATE_SUCCESS:
      toast.success("Subcategoría actualizada");
      return {
        loading: false,
        success: true,
      };
    case SUBCATEGORY_UPDATE_FAIL:
      toast.error("Error actualizando Subcategoría: " + action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
